/* ::::::::::::::::::: APP STARTS HERE :::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

//console.clear();

import "../scss/style.scss"; // loading in the style
import CreateAnnouncement from "./components/CreateAnnouncement.js";
import CreateFooter from "./components/CreateFooter.js";
import CreateHeader from "./components/CreateHeader.js";
import CreateSingleArticle from "./CreateSingleArticle.js";
import CreateMobileMenu from "./components/CreateMobileMenu.js";
import CreateLeftRail from "./components/CreateLeftRail.js";
import LoadJSON from "./components/LoadJSON.js";
import queryString from "query-string";

/* ::::::::::::: GLOBAL VARIALBES :::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::: */

if (import.meta.env.DEV === true){
	console.log("DEV environment");
	window.DEBUG = true;
}
else if (import.meta.env.PROD === true) {
	console.log("PROD environment");
	window.DEBUG = false;
}

window.GoogleAnalytics = (data) => {
    try { 
        gtag('event', data.event, {
			'screen-name': data.location,
			'interaction-type': data.type
        });

		//gtag('send', data.event, data.category, data.action, data.label, data.value);

    } 
    catch (Error) {
        console.debug(Error.message);
    }
    console.group("Google-Analytics");
    console.debug("event:", data.event);
    console.debug("location:", data.location);
    console.debug("interaction:",  data.type);
    console.groupEnd("Google-Analytics");
}


const init = async () => {

	let asciiArt = `
░▒▓████████▓▒░░▒▓███████▓▒░▒▓█▓▒░░▒▓█▓▒░░▒▓██████▓▒░       ░▒▓███████▓▒░░▒▓█▓▒░▒▓███████▓▒░        ░▒▓███████▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░ 
░▒▓█▓▒░      ░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░ 
░▒▓█▓▒░      ░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░ 
░▒▓██████▓▒░  ░▒▓██████▓▒░░▒▓████████▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░       ░▒▓██████▓▒░░▒▓████████▓▒░▒▓█▓▒░▒▓███████▓▒░░▒▓████████▓▒░▒▓█▓▒░ 
░▒▓█▓▒░             ░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░             ░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░ 
░▒▓█▓▒░             ░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░             ░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░ 
░▒▓████████▓▒░▒▓███████▓▒░░▒▓█▓▒░░▒▓█▓▒░░▒▓██████▓▒░       ░▒▓███████▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░      ░▒▓███████▓▒░░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░░▒▓█▓▒░▒▓█▓▒░`;

	console.log(":::::::::::::::::::::::::::::::::::::::::::");
	console.log(":::::::::::::: ESHO DIN SHIKHI ::::::::::::");
	console.log(asciiArt);
	console.log("version: 1.1");
	console.log("last updated: 1/04/2025 at 9.34 am ET");
	console.log(":::::::::::::::::::::::::::::::::::::::::::");



	window.breadCrum = [];
	let articleToLoadByDefault;
	let siteContainer = document.querySelector("#wrapper");
	let ContentContainer = document.querySelector(".content-container");
	let ArticleList = document.querySelector(".articleList");
	let SingleArticleContainer = document.querySelector(".single-article");
	let numberOfArticleToShow = 20;
	

	new LoadJSON({
		"url": "data/header-data.json",
		"callback": (data) => { 
			new CreateHeader(data);

			try {

				var didScroll;
				var lastScrollTop = 0;
				//var navbarHeight = document.querySelector('.header-nav').offsetHeight; 
				window.addEventListener("scroll", ()=> { didScroll = true;})

				function hasScrolled() {
			
					const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
					if (currentScrollTop > lastScrollTop) {
						// Scrolling down
						document.querySelector(".header-nav").classList.remove("nav-down");
						document.querySelector(".header-nav").classList.add("nav-up");
					} else {
						// Scrolling up
						document.querySelector(".header-nav").classList.add("nav-down");
						document.querySelector(".header-nav").classList.remove("nav-up");
					}
			
					lastScrollTop = currentScrollTop;
					
				}
				
				setInterval(function() { if (didScroll) { hasScrolled(); didScroll = false;}}, 250);

			}
			catch (Error) {}
			
		
		}
	});


	new LoadJSON({
		"url": "data/mobile-menu.json",
		"callback": (data) => { window.MMenu = new CreateMobileMenu(data);}
	});

	let showHideBtn = () => {
		let showHideBtn = document.createElement("div");
		showHideBtn.id = "show-hide-btn";
		ContentContainer.appendChild(showHideBtn);
		showHideBtn.addEventListener("click", (event) => {
			event.target.parentElement.setAttribute(
				"data-no-leftrail",
				"false"
			);
		});
	}

	showHideBtn();

	let showMore = () => {
		let articlePreviewContainer = document.querySelector( ".article-preview-container");
		let hiddenArticles = [];

		for (var i = 0; i < articlePreviewContainer.children.length; i++) {
			try {
				let tempItem = articlePreviewContainer.children[i];
				if (tempItem.classList.contains("hide")) {
					hiddenArticles.push(articlePreviewContainer.children[i]);
				}
			} catch (Error) {}
		}

		for (var j = 0; j < numberOfArticleToShow; j++) {
			try {
				let tempHiddenArticle = hiddenArticles[j];
				tempHiddenArticle.classList.remove("hide");
				tempHiddenArticle.classList.add("show");
			} catch (Error) {}
		}
	}

	console.groupEnd();

	/* ::::::: AccounceMent ::::::::::::::::::::::: */
	/* :::::::::::::::::::::::::::::::::::::::::::: */

	new CreateAnnouncement({
		"container": `.accouncements`,
		"data": [
			{
				"copy": `Notice: Terrorism in Bangladesh. Esho Din Shikhi condemns all forms of terrorism in Bangladesh and around the world.`,
				"url": "https://eshodinshikhi.com/?L0=1&CL=0&id=3327",
			},
			{
				"copy": `Terrorism in Islaam, click to listen the Lecture`,
				"url": "https://eshodinshikhi.com/?L0=1&CL=0&id=3324",
			},
			{
				"copy": `বর্তমানে ওয়েবসাইটটি নির্মাণাধীন আছে। কিছু অংশ এই মুহূর্তে কাজ করছে না। আমাদের সাথে ধৈর্য ধারণ করুন إن شاء الله`,
				"url": "",
			}
		]
	});

	/* ::::::: Left Rail ::::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::: */

	let LeftRail = new CreateLeftRail({ 
		"container": `.left-rail`,
		"logo": `images/logo.png`,
		"logo": {
			"src": `images/logo.png`,
			"alt": "Esho Din Shikhi Logo",
			"click": ()=> {
				//console.log("logo clicked");
				ClearQueryStrings ();
				location.reload();
			}
		}
	});
	new LoadJSON({"url": "data/searchbox.json","callback": (data) => { LeftRail.LoadSearch(data);}});
	new LoadJSON({"url": "data/book-information.json","callback": (data) => { LeftRail.LoadBookInformation(data);}});
	new LoadJSON({"url": "data/tag-cloud.json","callback": (data) => { LeftRail.LoadTagCloud(data);}});


	let CreateSubMenu = (data) => {

		let directory;
		removeParam("CL");
		ArticleList.innerHTML = "";
		SingleArticleContainer.innerHTML = "";
		let ArticlePreviewContainer = document.createElement("ul");
		ArticlePreviewContainer.classList.add("article-preview-container");
		ArticleList.appendChild(ArticlePreviewContainer);

		switch(data["level"]) {
			case 1:
				directory = window.menuData[breadCrum[0]];
				break;
			case 2:
				directory = window.menuData[breadCrum[0]]["subsection"][breadCrum[1]];
				break;
			case 3:
				directory = window.menuData[breadCrum[0]]["subsection"][breadCrum[1]]["subsection"][breadCrum[2]]
				break;
			case 4:
				directory = window.menuData[breadCrum[0]]["subsection"][breadCrum[1]]["subsection"][breadCrum[2]]["subsection"][breadCrum[3]]
				break;
			default:
				directory = window.menuData[breadCrum[0]];
			}

		if (directory["subsection"]) {
	
			for (let i = 0; i < directory["subsection"].length; i++) {
	
				CreateSubMenuInList({
					"index": i,
					"type": "submenu",
					"title":  directory["subsection"][i]["name"],
					"summary":  directory["subsection"][i]["content"],
					"container":ArticlePreviewContainer,
					"level": data["level"],
					"callbackfunc": data["callback"]?data["callback"]: ()=>{
						if (window.DEBUG) {
							console.log("do nothing");
						}
						
					}
				});
			}
		} 
		
		else {

			for (let i = 0; i < directory["content-list"].length; i++) {
				CreateArticleInList({
					"index": i,
					"type": directory["content-list"][i]["type"],
					"id": directory["content-list"][i]["id"],
					"title": directory["content-list"][i]["name"],
					"title-en": directory["content-list"][i]["name-en"],
					"summary": directory["content-list"][i]["snippet"],
					"container": ArticlePreviewContainer
				});
			}
		}

	}


	/* :::::::::::: Level 0 :::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */


	let ClearQueryStrings = () => {
		removeParam("id");
		removeParam("L0");
		removeParam("L1");
		removeParam("L2");
		removeParam("L3");
		removeParam("CL");
	}

	let OpenL0 = (whichOne) => {
		if (window.DEBUG) {
			console.log("::Open0:: ", whichOne);

			GoogleAnalytics ({
				"event": "page_view",
				"location": "menu-" + whichOne,
				"type": "click"
			});
		}
		
		ClearQueryStrings ();
		window.breadCrum[0] = whichOne;
		updateQueryStringParameter("L0",whichOne);
		MMenu.CloseMobileMenu();

		for (var i = 0; i < document.querySelectorAll(".dd-menu").length; i++) {
			let tempItem = document.querySelectorAll(".dd-menu")[i];
			tempItem.classList.remove("selected");
		}

		document.querySelector("#nav-" + whichOne).classList.add("selected");
		OpenL1();
	}

	

	/* :::::::::::: Level 1 :::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */

	let OpenL1 = () => {
		if (window.DEBUG) {
			console.log("::Open1:: ",window.breadCrum);
		}
		CreateSubMenu ({ "level": 1, "callback": OpenL2})
	}

	/* :::::::::::: Level 2 :::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */

	let OpenL2 = () => {
		if (window.DEBUG) {
			console.log("::Open2:: ",window.breadCrum);
		}
		CreateSubMenu ({ "level": 2, "callback": OpenL3})
	}

	/* :::::::::::: Level 3 :::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */

	let OpenL3 = () => {
		if (window.DEBUG) {
			console.log("::Open3:: ", window.breadCrum);
		}
		CreateSubMenu ({ "level": 3, "callback": OpenL4})
	}

	/* :::::::::::: Level 4 :::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */

	let OpenL4 = () => {
		if (window.DEBUG) {
			console.log("::Open4:: ", window.breadCrum);
		}
		CreateSubMenu ({ "level": 4, "callback": ""})
	}


	let updateQueryStringParameter = (key, value) => {

		if (window.DEBUG) {
			console.group("QueryUpdate");
			console.log("key: ", key);
			console.log("value: ", value);
			console.groupEnd();
		}

		const urlSearchParams = new URLSearchParams(window.location.search);
		urlSearchParams.set(key, value);
		const newUrl = window.location.pathname + "?" + urlSearchParams.toString();
		window.history.pushState({ path: newUrl }, "", newUrl);

		let browserHistory = window.history;

		if (window.DEBUG) {
			console.log("::: HISTORY:::: ", browserHistory);
		}
		
	}

	let removeParam = (paramName) => {
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.delete(paramName);
		if (history.replaceState) {
			let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
			let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname +  searchString + window.location.hash;
			history.replaceState(null, '', newUrl);
		}
	}

	let resetURLlocation = () => {
		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		url.search = "";
		return url;
	}

	let FindFootNotes = (data) => {
		const regex = /\(\((.*?)\)\)/g;
		const matches = [...data.matchAll(regex)];
		const results = matches.map(match => match[1]);
		return results;
	}

	let RemoveSpan = (data) => {
		// Example text block
		let textBlock = data;

		// Remove all occurrences
		textBlock = textBlock.replace(`<span style="font-family: 'Arabic Symbols';">`, '');
		textBlock = textBlock.replace("</span>", '');
		return textBlock;
	}

	function htmlToText(html) {
		var temp = document.createElement('div');
		temp.innerHTML = html;
		return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
	}

	let loadArticle = (id) => {
		if (window.DEBUG) {
			console.log(":: Load-Article ::::: >> ",id);
		}
		
		if (id != "" || id != undefined) {
			ArticleList.innerHTML = "";
			SingleArticleContainer.innerHTML = "";

			new LoadJSON({
				"url": `data/${id}.json`,
				"callback": (data) => {
					let PlainData = htmlToText(data.content);
					data.footnote = FindFootNotes(String(PlainData));
					new CreateSingleArticle(data);
					updateQueryStringParameter("id", id);
				}
			});
		}

		try { updateQueryStringParameter("CL",window.breadCrum.length);} catch (Error) {}

		GoogleAnalytics ({
			"event": "page_view",
			"location": "page-id-" + id,
			"type": "click"
		});
		
	}

	/* :::::::::::: Footer ::::::::::::::::::::::: */
	/* ::::::::::::::::::::::::::::::::::::::::::: */

	new LoadJSON({
		"url": "data/footer-data.json",
		"callback": (data) => { 
			if (window.DEBUG) {
				//console.log(data);
			}
			
			data.logo.click = ()=> {
				ClearQueryStrings ();
				location.reload();
			}
			new CreateFooter(data);
		},
	});


	let CreateSubMenuInList = (data) => {
		let level = Number(data.level);
		let callbackFunc = data.callbackfunc;

		let EachArticleMenu = document.createElement("li");
		EachArticleMenu.classList.add("each-article");
		EachArticleMenu.setAttribute("data-index", data.index);
		EachArticleMenu.setAttribute("data-type", data.type);
		EachArticleMenu.setAttribute("data-level", data.level);
		if (data["level0-index"]) {
			EachArticleMenu.setAttribute("data-level0-index", data["level0-index"]);
		}

		if (level == 1) { EachArticleMenu.setAttribute("data-level1-index", data.index);}
		
		if (data.title) {
			let articleTitle = document.createElement("div");
			articleTitle.classList.add("title");
			articleTitle.innerHTML = data.title;
			EachArticleMenu.appendChild(articleTitle);
		}

		if (data.summary) {
			let articleSummary = document.createElement("div");
			articleSummary.classList.add("summary", "short");
			articleSummary.innerHTML = data.summary;
			EachArticleMenu.appendChild(articleSummary);
		}

		data.container.appendChild(EachArticleMenu);
		EachArticleMenu.addEventListener("click", (event) => {
			window.breadCrum[level] = Number(data.index);
			event.target.setAttribute("data-index", Number(data.index));
			callbackFunc(event.target);
			removeParam("id");
			updateQueryStringParameter( "L" + level, event.target.dataset.index);

		});
	}

	let CreateArticleInList = (data) => {
		let title = data.title;
		let summary = data.summary;
		let id = data.id;
		let index = data.index;
		let tags = data.tags;
		let icons = data.icons;
		let container = data.container;
		let type = data.type;

		

		let eachArticle = document.createElement("li");
		eachArticle.classList.add("each-article");
		eachArticle.setAttribute("data-index", index);
		eachArticle.setAttribute("data-type", "article");
		eachArticle.setAttribute("data-id", id);
		eachArticle.setAttribute("aria-label", "article-id-" + id);
		if (data["class"]) {
			eachArticle.classList.add(data.class);
		}

		let articleTitle = document.createElement("div");
		articleTitle.classList.add("title");
		articleTitle.innerHTML = title;
		eachArticle.appendChild(articleTitle);

		if (type == "audio") {
			let audioPreviewGraphics = document.createElement("div");
			audioPreviewGraphics.classList.add("audioPreviewGraphics");
			eachArticle.appendChild(audioPreviewGraphics);
		}

		let articleSummary = document.createElement("div");
		articleSummary.classList.add("summary");
		articleSummary.innerHTML = summary;
		eachArticle.appendChild(articleSummary);

		// eachArticle.innerHTML = ContentList[i].name;
		container.appendChild(eachArticle);

		if (type == "post" || "audio") {
			eachArticle.addEventListener("click", (event) => {
				// window.open("https://eds.cubicsignal.com/article-page.html?id="+ id +"&type=article");
				loadArticle(id);
			});
		}
	}


	/* :::::::::::: Site Navigation Top ::::::::::::::::::::::: */
	/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

	let createTopMenu = (menuData) => {

		window.breadCrum = new Array();

		let CreateMenus = (data) => {

			try {
				let tempTitle = data["name"];
				let navItem = document.createElement("li");
				navItem.id = "nav-" + data.counter;
				navItem.setAttribute("data-index", data.counter);
				navItem.setAttribute("data-Level", 0);
				//navItem.tabIndex = Number(data.counter);
				navItem.tabIndex = 0;
				navItem.classList.add( "dd-menu", "menu-level-0", "nav-item-" + data.counter);
				navItem.innerHTML = tempTitle;
				data.container.appendChild(navItem);

				navItem.addEventListener("click", (event) => {
					let currentIndex = Number(event.target.dataset.index);
					menuData["callback"](currentIndex);
				});

			}
			catch (Error) {
				if (window.DEBUG) {
					console.log("Error creating Menu ");
				}
			}

			
		}

		new LoadJSON({
			"url": menuData["feed"],
			"callback": (data) => {

				if (window.DEBUG) {
					//console.log(data);
				}

				let subsections = data.subsection;
				window.menuData = data.subsection;
				menuData["feed-load-completed"]();
				siteContainer.classList.remove("hide");

				let NavContainer = document.querySelector(".header-nav");
				NavContainer.classList.add("no-print");
				let navTop = document.createElement("ul");
				navTop.classList.add("nav-top");
				NavContainer.appendChild(navTop);

				let MobileContainer = document.querySelector("#mobile-menu .nav-menu");

				for (var i = 0; i < subsections.length; i++) {
					subsections[i].counter = i;

					// desktop menu :::::::::::
					subsections[i].container = navTop;
					CreateMenus(subsections[i]);

					// mobile menu ::::::::::::
					subsections[i].container = MobileContainer;
					CreateMenus(subsections[i]);
				}
			},
		});
	}


	/* :: -------------------------------------------------------------------- */
	/* :: :::::::::: Parsing Query String :::::::::::::::::::::::::::::::::::: */


	let ParseQueryStringAndLoad = (parsedData) => {

		if (window.DEBUG) {
			console.group("query-string");
			console.log(parsedData);
			console.log("level-0: ",parsedData.L0);
			console.log("level-1: ",parsedData.L1);
			console.log("level-2: ",parsedData.L2);
			console.log("level-3: ",parsedData.L3);
			console.log("level-4: ",parsedData.L4);
			console.log("article-id: ",parsedData.id);
			console.groupEnd();
		}
		
		let LoadDefault = () => {
			articleToLoadByDefault = undefined;

			new LoadJSON({
				url: "data/intropagelist.json",
				callback: (data) => {
					let ArticlePreviewContainer = document.createElement("ul");
					ArticlePreviewContainer.classList.add(
						"article-preview-container"
					);
					ArticleList.appendChild(ArticlePreviewContainer);

					if (data.length > numberOfArticleToShow) {
						let viewMoreButton = document.createElement("div");
						viewMoreButton.classList.add("more-article");
						viewMoreButton.innerHTML = "আরো প্রবন্ধ ও অডিও-র জন্য এখানে ক্লিক করুন";
						ArticleList.appendChild(viewMoreButton);
						viewMoreButton.addEventListener("click", () => {
							showMore();
						});
					}

					for (let i = 0; i < data.length; i++) {
						CreateArticleInList({
							"index": i,
							"type": data[i].type,
							"id": data[i].id,
							"title": data[i]["title"],
							"title-en": data[i]["name-en"],
							"summary": data[i]["summary"],
							"container": ArticlePreviewContainer,
							"class": i < numberOfArticleToShow ? "show" : "hide",
						});
					}
				},
			});
		}

		if (parsedData.id != undefined) {
			articleToLoadByDefault = Number(parsedData.id);
			loadArticle(articleToLoadByDefault);
		}
		else {

			if ((parsedData.L0 != undefined) && (parsedData.L1 != undefined) && (parsedData.L2 != undefined) && (parsedData.L3 != undefined)) {
				// load level 3
				window.breadCrum[0] = Number(parsedData.L0);
				window.breadCrum[1] = Number(parsedData.L1);
				window.breadCrum[2] = Number(parsedData.L2);
				window.breadCrum[3] = Number(parsedData.L3);
				try { OpenL4 (); } catch (Error) {
					ClearQueryStrings ();
					LoadDefault ();
				}
			}
			else if ((parsedData.L0 != undefined) && (parsedData.L1 != undefined) && (parsedData.L2 != undefined)) {
				// load level 2
				window.breadCrum[0] = Number(parsedData.L0);
				window.breadCrum[1] = Number(parsedData.L1);
				window.breadCrum[2] = Number(parsedData.L2);
				try { OpenL3 (); } catch (Error) {
					ClearQueryStrings ();
					LoadDefault ();
				}
			}
			else if ((parsedData.L0 != undefined) && (parsedData.L1 != undefined)) {
				// load level 1
				window.breadCrum[0] = Number(parsedData.L0);
				window.breadCrum[1] = Number(parsedData.L1);
				try { OpenL2 (); } catch (Error) {
					ClearQueryStrings ();
					LoadDefault ();
				}
			}
			else if (parsedData.L0 != undefined) {
				// load level 0
				window.breadCrum[0] = Number(parsedData.L0);
				try { OpenL1 (); } catch (Error) {
					ClearQueryStrings ();
					LoadDefault ();
				}
			}
			else { 
				ClearQueryStrings ();
				LoadDefault (); 
			}

		}
		
	}


	createTopMenu({
		"feed": "data/sitemap.json",
		"callback": (index)=> {
			OpenL0 (index);
		},
		"feed-load-completed": ()=> {
			ParseQueryStringAndLoad (queryString.parse(location.search));
		}
	});

	
	/* :: :::::::::: End of Parsing Query String ::::::::::::::::::::::::::::: */
	/* :: -------------------------------------------------------------------- */
}



window.addEventListener("popstate", () => {
	window.location.reload();
});

window.addEventListener("hashchange", (event) => {
	if (window.DEBUG) {
		console.log("Fragment identifier or URL changed");
	}
	
});

window.onload = (event) => {
	init();
};