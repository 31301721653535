import Media from "./PodcastPlayer.js";

export default class AudioPlayer {

    constructor (data) {

        initAudioPlayer (data);

        console.log(data);

        let currentpercentage = 0;
        //console.log(data);

        function updateStraightBar (element, percentage) {
            let progbar = document.querySelector(element);
            progbar.style.setProperty('width', percentage + "%");
        }

    
        function DOMcreation (container, uniqueID) {
    
            var wrapper = document.createElement("div");
            wrapper.classList.add("audio-player-wrapper");
            wrapper.id = "player" + uniqueID;
    
            var playerContainer = document.createElement("div");
            playerContainer.classList.add("player-container");
            wrapper.appendChild(playerContainer);
    
            var ct = document.createElement("div");
            ct.classList.add("ct");
            playerContainer.appendChild(ct);
    
            var progressBarAndNumbers = document.createElement("div");
            progressBarAndNumbers.classList.add("progressBarAndNumbers");
            playerContainer.appendChild(progressBarAndNumbers);
    
            var currentTimeContainer = document.createElement("div");
            currentTimeContainer.classList.add("currentTimeContainer");
            progressBarAndNumbers.appendChild(currentTimeContainer);
    
            var mobileProgressBar = document.createElement("div");
            mobileProgressBar.classList.add("mob");
            mobileProgressBar.classList.add("audio-progress-bar");
            progressBarAndNumbers.appendChild(mobileProgressBar);
    
            var audioDurationContainer = document.createElement("div");
            audioDurationContainer.classList.add("audioDurationContainer");
            progressBarAndNumbers.appendChild(audioDurationContainer);
    
            var MPBforeground = document.createElement("div");
            MPBforeground.classList.add("foreground");
            mobileProgressBar.appendChild(MPBforeground);
    
            var MPBbackground = document.createElement("div");
            MPBbackground.classList.add("background");
            mobileProgressBar.appendChild(MPBbackground);
    
            var audioPlayer = document.createElement("div");
            audioPlayer.classList.add("audio-player");
            audioPlayer.id = "player-" + uniqueID;
            ct.appendChild(audioPlayer);
    
            var arrowRewind = document.createElement("div");
            arrowRewind.setAttribute("aria-label","audio backward");
            arrowRewind.classList.add("arrow");
            arrowRewind.classList.add("rewind");
            ct.appendChild(arrowRewind);
    
            var arrowForward = document.createElement("div");
            arrowForward.setAttribute("aria-label","audio forward");
            arrowForward.classList.add("arrow");
            arrowForward.classList.add("forward");
            ct.appendChild(arrowForward);
    
            //console.log("container type: ",typeof 'container');
            //console.log("container : ",container);
            if (container) { 
                try {
                    document.getElementById(container).appendChild(wrapper); 
                }
                catch (Error) {
                   // container.appendChild(wrapper);
                    console.log(Error);
                }
                 
            }
            else { document.body.appendChild(wrapper); }
    
    
        }

        function createAudioList (audioList,container, audioPlayer) {

            var audioListContainer = document.createElement("ul");
            audioListContainer.classList.add("audio-list");
            container.appendChild(audioListContainer);

            for (var i = 0; i < audioList.length; i++) {
                var eachListItem = document.createElement("li");
                eachListItem.classList.add("audio-list-item");
                eachListItem.innerHTML = audioList[i]["name"];
                eachListItem.setAttribute("data-index",i);
                eachListItem.setAttribute("data-url", audioList[i]["url"]);

                if (i == 0) {
                    eachListItem.classList.add("selected");
                }

                eachListItem.addEventListener("click",function(event) {
                   // console.log(event.target.dataset);
                   // console.log(audioPlayer);
                    audioPlayer.LoadAudio(event.target.dataset.url);

                    for (const child of audioListContainer.children) {
                       // console.log(child);
                        child.classList.remove("selected");
                    }

                    event.target.classList.add("selected");
                })
                audioListContainer.appendChild(eachListItem);


            }

        }


        function initAudioPlayer(data) {
    
            var PlayerHost;
            let AudioDuration;
            let root = document.documentElement;
    
            if (data.container) { 
                PlayerHost = data.container;
                if (typeof PlayerHost == "object") {
                    PlayerHost.setAttribute("id","EDS-audio-player");
                    PlayerHost = "EDS-audio-player";
                }
                //console.log("type of playerhost: ",typeof PlayerHost,PlayerHost);
            } else { PlayerHost = null;}
    
            DOMcreation (PlayerHost,data.id);
            let daContainer = document.querySelector("#player" + data.id + ".audio-player-wrapper");

        
    
            if (data.duration) {
                var totalTimeMin = Math.floor(data.duration / 60);
                var totalTimeSec = Math.floor(data.duration % 60);
    
                try {
                    document.querySelector("#player" + data.id + " .currentTimeContainer").innerHTML = "00:00";
                    document.querySelector("#player" + data.id + " .audioDurationContainer").innerHTML = `${totalTimeMin}:${totalTimeSec}`;
                } catch (Error) {}
               
            }
    
            daContainer.classList.add("mob-v2");
            daContainer.style.visibility = "visible";

            let firstAudioFile = ( Array.isArray(data["location"]) ? data.location[0]["url"] : data.location);
    
            let AudPlayer = new Media({
                "wrapper": document.getElementById(data.container),
                "outofviewport": false,
                "container": "#player" + data.id + " #player-" + data.id, // div ID of the video container
                "type": "audio",
                "variablespeed": ["1.0","1.25","1.50","2.0"],
                "playbutton": "https://storage.googleapis.com/nytpi/paidposts/cdn/player-icons/icon-play.svg", // this images needed to be hosted in google bucket
                "pausebutton": "https://storage.googleapis.com/nytpi/paidposts/cdn/player-icons/icon-pause.svg", // this images needed to be hosted in google bucket
                "mutebutton": "https://storage.googleapis.com/nytpi/paidposts/2023/bch/images/btn-sound-off-black.svg",
                "unmutebutton": "https://storage.googleapis.com/nytpi/paidposts/2023/bch/images/btn-sound-on-black.svg",
                "id": data.id,
                "mp3": firstAudioFile, // this mp3 file needed to be hosted in google bucket
                "callbackfunction": {
                onPlay: function(event){
                    root.style.setProperty('--audio-duration', Math.round(event.target.duration) + "s");
                    try {
                        GoogleAnalytics ({
                            "event": "audio_started",
                            "location": data.location[0]["name"],
                            "type": "click"
                        });
                    }
                    catch (Error) {
                        console.log("error sending GA audio data");
                    }   
                },
                onEnd: function() {
                    try {
                        GoogleAnalytics ({
                            "event": "audio_completed",
                            "location": data.location[0]["name"],
                            "type": "click"
                        });
                    }
                    catch (Error) {
                        console.log("error sending GA audio data");
                    }
                },
                onPause: function () {},
                onRewind: function () {},
                onForward: function () {},
                timeupdate: function(event) {
    
                        AudioDuration = event.target.duration;
                        let MediaUnits = AudioDuration / 100;
                        let CurTime = event.target.currentTime;
                        let CurTimeRounded = Math.round(CurTime);
                        let CurPercent = CurTime / MediaUnits;
                        let curPerRounded = Math.round(CurPercent);
                        
                        currentpercentage = Math.round(CurPercent);
                        var curTimeMin = Math.floor(CurTime / 60);
                        var curTimeSec = Math.floor(CurTime % 60);
                        var totalTimeMin = Math.floor(AudioDuration / 60);
                        var totalTimeSec = Math.floor(AudioDuration % 60);
    
                        if (curTimeMin < 10) {curTimeMin = "0" + curTimeMin;}
                        if (curTimeSec < 10) {curTimeSec = `0${curTimeSec}`}
                        if (totalTimeMin < 10) {totalTimeMin = "0" + totalTimeMin;}
                        if (totalTimeSec < 10) {totalTimeSec = "0" + Number(totalTimeSec);}

                        try {
                            document.querySelector("#player" + data.id + " .currentTimeContainer").innerHTML = `${curTimeMin}:${curTimeSec}`;
                            document.querySelector("#player" + data.id + " .audioDurationContainer").innerHTML = `${totalTimeMin}:${totalTimeSec}`;
                            updateStraightBar ("#player" + data.id + " .mob.audio-progress-bar .foreground",currentpercentage);
                        }
                        catch (Error) {}
    
                    }
                }
            });

            if ( Array.isArray(data["location"])) {
                createAudioList (data["location"],daContainer,AudPlayer);
            }
    
            document.querySelector("#player" + data.id + " .rewind").addEventListener("click",function(event){
                AudPlayer.Rewind(15);
            });
    
            document.querySelector("#player" + data.id + " .forward").addEventListener("click",function(event){
                AudPlayer.Forward(15);
            });
    
    
            let mobilePBar = document.querySelector("#" + data.container + " .mob-v2 .audio-progress-bar");
            //console.log("mobile player bar: ",mobilePBar);
    
            mobilePBar.addEventListener("click",function(event){
    
                var widthOfmbar = this.clientWidth;
                var rect = event.target.getBoundingClientRect();
                var Xval = Math.round(event.clientX - rect.left); //x position within the element.
                var percentage = (Xval / widthOfmbar) * 100;
                AudPlayer.Jump(percentage);
    
            });


            function fixCircleSizePos (whichEle) {
               // console.log("whichOne : ", whichEle);
               // console.log('current circle: ',window.currentCircle);
                document.getElementById(whichEle).classList.remove("non-interacted");
                document.getElementById(whichEle).classList.add("interacted");
                document.getElementById(whichEle).querySelector(".episode-visuals").classList.add("expand");
                let collapsedCircle = document.querySelector("#" + whichEle + " .visual-collapsed");
                collapsedCircle.setAttribute("circle-radius",window.circleDiameter);
                collapsedCircle.style.clipPath = "circle(" + window.circleDiameter + " at var(--circle-new-pos))";
                collapsedCircle.classList.add("interacted");
                collapsedCircle.classList.remove("fresh");
            }
            
        }
    

    }

}
