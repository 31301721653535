
// import Swiper from "swiper";
// import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Swiper from 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.mjs';
//import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
export default class CreateAnnouncement {

    
    constructor (data ) {

        if (window.DEBUG) {
            console.group("::::: Announcement :::::");
           // console.log(data);
            console.groupEnd();
        }
       
        let Scope = this;
        let container = document.querySelector(data["container"]);
        container.classList.add("show");
        container.classList.add("no-print");
        Scope.container = container;

        let title = document.createElement("div");
        container.appendChild(title);
        title.setAttribute("class","title");
        title.innerHTML = "ঘোষণা";

        let createContent = document.createElement("div");
        container.appendChild(createContent);
        createContent.setAttribute("class","content");

        let slideshow = document.createElement("div");
        slideshow.setAttribute("class","swiper");
        createContent.appendChild(slideshow);

        let slideshowWraper = document.createElement("div");
        slideshowWraper.setAttribute("class","swiper-wrapper");
        slideshow.appendChild(slideshowWraper);

        let swiperPagination = document.createElement("div");
        swiperPagination.setAttribute("class","swiper-pagination");
        slideshow.appendChild(swiperPagination);

        let swiperNavPrev = document.createElement("div");
        swiperNavPrev.setAttribute("class","swiper-button-prev");
        slideshow.appendChild(swiperNavPrev);

        let swiperNavNext = document.createElement("div");
        swiperNavNext.setAttribute("class","swiper-button-next");
        slideshow.appendChild(swiperNavNext);  


        data["data"].forEach((item,index) => {
            if (window.DEBUG){
                console.log(item,index);
            }
           
            let eachSlide = document.createElement("a");
            eachSlide.setAttribute("class","swiper-slide");
            eachSlide.innerHTML = item["copy"];
            eachSlide.setAttribute("href",item["url"]);
            eachSlide.setAttribute("data-index", index);
            slideshowWraper.appendChild(eachSlide);
        });

        new Swiper(data["container"] +' .swiper', {
            //"modules": [Navigation, Pagination, Autoplay],
            "spaceBetween": 0,
            "loop": true,
            "pagination": {
                "el": data["container"] +  ' .swiper-pagination',
                "type": 'bullets',
                "clickable": true,
            },
            "autoplay": {
                "delay": 5000,
                "disableOnInteraction": true,
                "pauseOnMouseEnter": true,
                "stopOnLastSlide": true
		    },
            "navigation": {
                "nextEl": data["container"] + ' .swiper-button-next',
                "prevEl": data["container"] + ' .swiper-button-prev',
            },
        
        });

        let createCloseBtn = document.createElement("div");
        container.appendChild(createCloseBtn);
        createCloseBtn.setAttribute("class","closeBtn");

        createCloseBtn.addEventListener("click",function(){
            container.classList.remove("show");
        })

        return Scope;
        
    }

    reset () {
        if (window.DEBUG) {
            console.log("accouncement reset function: ");
            console.log(this);
        }
       
        this.container.innerHTML = '';
    }


}