import "../scss/modules/single-article-content.scss"; 
import CreateTags from "./components/CreateTags.js";
import CreateShareIcons from "./components/CreateShareIcons.js";
import AudioPlayer from "./components/Audio-Player.js";
import LoadJSON from "./components/LoadJSON.js";
export default class CreateSingleArticle {

    
    constructor (data ) {

        let Scope = this;
        let container = document.querySelector(data["container"]); 
        container.setAttribute("data-type",data["type"]);
        container.setAttribute("data-id",data["id"]);
        Scope.container = container;

      

        if (data["header"]) {
            let Header = document.createElement("div");
            container.appendChild(Header);
            Header.innerHTML = data["header"];
            Header.setAttribute("class","header");
        }
      
        /* ::::::: Meta-Data Section ::::::::::::: */
        /* ::::::::::::::::::::::::::::::::::::::: */

        let ArticleMetaData = document.createElement("div");
        container.appendChild(ArticleMetaData);
        ArticleMetaData.setAttribute("class","meta-data-container");

        let ArticleDate = document.createElement("div");
        ArticleMetaData.appendChild(ArticleDate);
        ArticleDate.innerHTML = data["date"]?data["date"]:"unavailable";
        ArticleDate.setAttribute("class","date");

        let CRtags = new CreateTags({
            "container": ArticleMetaData,
            "tags": data["tags"]
        });

        let Share = new CreateShareIcons({
            "container": ArticleMetaData,
            "id": data["id"],
            "url": data["url"],
            "printer-friendly-url": data["printer-friendly-url"]
        });

        
        /*
        if (data["summary"]) {
            let Summary = document.createElement("div");
            container.appendChild(Summary);
            Summary.innerHTML = data["summary"];
            Summary.setAttribute("class","summary");
        }
        */
        

        if (data["audio_files"]) {
            // console.log(":::::: AUDIO Files here :::::");
            // console.log(data["audio_files"]);
            // console.log("name: ", data["audio_files"][0]["name"]);

            let AudioPlayerContainer = document.createElement("div");
            container.appendChild(AudioPlayerContainer);
            AudioPlayerContainer.setAttribute("id","AudioPlayerContainer");

            new AudioPlayer({
                "id": "ep-1",
                "container": "AudioPlayerContainer",
                "location": [
                    {
                        "name": data["audio_files"][0]["name"],
                        "url": data["audio_files"][0]["url"]
                    },
                    /*
                    {
                        "name":"একসময় দেখা যাবে যে",
                        "url": "https://storage.googleapis.com/nytpi/paidposts/2022/BCH/podcast-player/audio-files/BCH_Trailer.mp3"
                    }
                    */
                ],
                "duration": 5880
            });
            
        }

        if (data["content"]) {
            let Content = document.createElement("div");
            container.appendChild(Content);
            Content.innerHTML = data["content"];
            Content.setAttribute("class","content");
        }

        if (data['footnote']) {

			new LoadJSON({
				"url": "data/footnote-data.json",
				"callback": (allfootnotes) => { 

                   // console.log(allfootnotes);

					let footNoteContainer = document.createElement("ul");
					footNoteContainer.classList.add("footNote-container");
					container.appendChild(footNoteContainer);

                   // console.log("data-footnote");
                   // console.log(data['footnote']);

					data['footnote'].forEach((eachFN, index) => {

						allfootnotes.forEach((eachEntry, index) => {
							if (eachEntry["arabic"] == eachFN) {

                               // console.log(eachEntry);

								let tempfootnote = document.createElement("li");
								tempfootnote.classList.add("each-footnote");
								tempfootnote.setAttribute("data-index",index);
								//tempfootnote.setAttribute("data-url",eachEntry["url"]);
                                tempfootnote.innerHTML = eachFN;
								footNoteContainer.appendChild(tempfootnote);

                                /*
								let compiledEachFootnote = 
								`<div class='arabic-fn'>${eachEntry["arabic"]}</div>` +
								`<div class='bangla-fn'>${eachEntry["bangla"]}</div>` +
								`<div class='english-fn'>${eachEntry["english"]}</div>` 
								
								tempfootnote.innerHTML = compiledEachFootnote; 

								tempfootnote.addEventListener("click", (event)=> {
									if (event.target.dataset.url) { window.open(event.target.dataset.url,"_blank"); }
								})
                                */
							}
						});
					});

				},
			});
		
        }

        return Scope;
        
    }

    reset () {
        this.container.innerHTML = '';
    }


}