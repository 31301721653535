//import "../scss/modules/nav.scss";
export default class CreateHeader {
  
    constructor (data ) {

        let headerNav = document.querySelector(".header-nav");
        let mobileOnly = document.createElement("div");
        headerNav.appendChild(mobileOnly);
        mobileOnly.setAttribute("class","mobile-only");

        let headerLogo = document.createElement("div");
        let headerLogoAtag = document.createElement("a");
        let headerLogoImg = document.createElement("img");
        headerLogoImg.setAttribute("src",data["logo"]["image"]);
        headerLogo.setAttribute("class","logo");
        headerLogoAtag.setAttribute("href",data["logo"]["link"]);
        headerLogoAtag.setAttribute("data-id",data["logo"]["id"]);
        headerLogoAtag.appendChild(headerLogoImg);
        headerLogo.appendChild(headerLogoAtag);
        mobileOnly.appendChild(headerLogo);
       
        let headerHamberMenu = document.createElement("div");
        headerHamberMenu.setAttribute("class","hamburger-menu");
        mobileOnly.appendChild(headerHamberMenu);

        let headerOverlay = document.createElement("div");
        headerOverlay.setAttribute("class","overlay");
        mobileOnly.appendChild(headerOverlay);    
        
        let mobileMenu = document.getElementById("mobile-menu");

        headerHamberMenu.addEventListener("click",function(){
            console.log("open mobile menu");
            mobileMenu.classList.add("show");
        })

    }

   


}