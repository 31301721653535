
//import "../scss/modules/sharebox.scss";

export default class CreateShareIcons {

    constructor (data ) {

        let container = data["container"]; 

        /* :::::::::: icons :::::::: */
        let createIcons = document.createElement("ul");
        container.appendChild(createIcons);

        createIcons.setAttribute("class","icons");
        createIcons.classList.add("sharebox");

        /*
        let createIconAdd = document.createElement("li");
        createIconAdd.setAttribute("class","icon icon-add");
        createIcons.appendChild(createIconAdd);
        createIconAdd.addEventListener("click",function(){
            console.log("icon-add clicked");
            console.log("ID: ",data["id"]);
        });
        */

        let createIconEmail = document.createElement("li");
        createIconEmail.setAttribute("class","icon icon-email");
        createIcons.appendChild(createIconEmail);
        createIconEmail.addEventListener("click",function(){
            console.log("icon-email clicked");
            console.log("url for email: ",window.location.href);
           // sendEmail("example@example.com", "Hello", `check out this url: ${window.location.href}`);

        });

        function sendEmail(address, subject, body) {
           // window.location.href = `mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

            window.open(`mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
           // x.close();
        }


     

        let createIconPrint = document.createElement("li");
        createIconPrint.setAttribute("class","icon icon-print");
        createIcons.appendChild(createIconPrint);
        createIconPrint.addEventListener("click",function(){
           // console.log("icon-print clicked");
          //  console.log("printer-friendly-url for email: ",data["printer-friendly-url"]);
            window.print();
        });
        /* :::::::: icons ::::::::::: */


    }

   

}




