

//import "../scss/modules/footer.scss"; 
export default class CreateFooter {

    
    constructor (data ) {

        let daFooter = document.querySelector(".footer");

         /*

        let footerNavEle = document.createElement("ul");
        daFooter.appendChild(footerNavEle);
        footerNavEle.setAttribute("id","footer-nav");
        footerNavEle.setAttribute("class","footer-nav");


       
        data["nav"].forEach((element) => {
            let eachfooterEle = document.createElement("li");
            let eachfooterEleAtag = document.createElement("a");
            eachfooterEleAtag.innerHTML = element["name"];
            eachfooterEleAtag.setAttribute("href",element["link"]);
            eachfooterEleAtag.setAttribute("data-id",element["id"]);
            eachfooterEle.appendChild(eachfooterEleAtag);
            footerNavEle.appendChild(eachfooterEle);
        });
        */

        let footerLogo = document.createElement("div");
        footerLogo.setAttribute("class","logo");
        let footerLogoAtag = document.createElement("a");
        let footerLogoImg = document.createElement("img");
        footerLogoImg.setAttribute("alt","logo");
        //const imgUrl = new URL(data["logo"]["image"], import.meta.url).href;
        footerLogoImg.setAttribute("src",data["logo"]["image"]);
       // footerLogoAtag.setAttribute("href",data["logo"]["link"]);
        footerLogoAtag.appendChild(footerLogoImg);
        footerLogo.appendChild(footerLogoAtag);
        daFooter.appendChild(footerLogo);

        footerLogo.addEventListener("click", (event)=> {
            data.logo.click(event);
        })


        let footerCopyRight = document.createElement("div");
        footerCopyRight.innerHTML = data["copyright-information"];
        footerCopyRight.setAttribute("class","copy");
        
        daFooter.appendChild(footerCopyRight);


        function getImageUrl(name) {
            return new URL(`./dir/${name}.png`, import.meta.url).href
        }


      

    }


}