export default class CreateMobileMenu {

    constructor (data ) {

        let Stage = this;
        let container = document.querySelector(data["container"]);
        Stage.container = container;

        let closeBtn = document.createElement("div");
        container.appendChild(closeBtn);
        closeBtn.setAttribute("class","closeBtn");
        closeBtn.addEventListener("click", () => {
            //let mobileMenu = document.getElementById("mobile-menu");
            //mobileMenu.classList.remove("show");
            this.CloseMobileMenu();
        });

        try {
            if ( data["search"]["label"]) {
                let SearchBar = document.createElement("div");
                container.appendChild(SearchBar);
                SearchBar.innerHTML = data["search"]["label"];
                SearchBar.setAttribute("class","search-bar");

                let SearchMenuContainer = document.createElement("ul");
                SearchMenuContainer.setAttribute("class","search-menu");
                container.appendChild(SearchMenuContainer);
        
                data["search"]["menu"].forEach((each) => {
        
                    let eachNavItem = document.createElement("li");
                    eachNavItem.innerHTML = each["label"];
                    eachNavItem.setAttribute("data-id",each["id"]);
                    SearchMenuContainer.appendChild(eachNavItem);
                    eachNavItem.addEventListener("click",function(event){
                        console.log(event.target);
                    });
        
                });

                let horLine1 = document.createElement("div");
                container.appendChild(horLine1);
                horLine1.setAttribute("class","hor-line");
            }
        }
        catch (Error) {}
       
        let navMenuContainer = document.createElement("ul");
        navMenuContainer.setAttribute("class","nav-menu");
        container.appendChild(navMenuContainer);

        data["nav-menu"].forEach((each) => {

            let eachNavItem = document.createElement("li");
            eachNavItem.innerHTML = each["label"];
            eachNavItem.setAttribute("data-id",each["id"]);
            navMenuContainer.appendChild(eachNavItem);
            eachNavItem.addEventListener("click",function(event){
                console.log(event.target);
            });

        });

        let horLine2 = document.createElement("div");
        container.appendChild(horLine2);
        horLine2.setAttribute("class","hor-line");

        if (data["footer-nav"]) {

            let footerNavContainer = document.createElement("ul");
            footerNavContainer.setAttribute("class","footer-nav");
            container.appendChild(footerNavContainer);

            data["footer-nav"].forEach((each) => {

                let eachNavItem = document.createElement("li");
                eachNavItem.innerHTML = each["label"];
                eachNavItem.setAttribute("data-id",each["id"]);
                footerNavContainer.appendChild(eachNavItem);
                eachNavItem.addEventListener("click",function(event){
                    console.log(event.target);
                });
    
            });

        }

        let logo = document.createElement("div");
        container.appendChild(logo);
        logo.setAttribute("class","logo");

        if (data["subscribe"]) {
            let subscribeBtn = document.createElement("div");
            container.appendChild(subscribeBtn);
            subscribeBtn.setAttribute("class","subscribe");
            subscribeBtn.innerHTML = data["subscribe"];
            subscribeBtn.addEventListener("click",function(event){
                console.log(event.target);
            });
        }
      
        return Stage;
    }

    CloseMobileMenu () {
        let mobileMenu = document.getElementById("mobile-menu");
        mobileMenu.classList.remove("show");
    }

}