// import Swiper from "swiper";
// import { Navigation, Pagination} from 'swiper/modules';
//import Swiper, { Navigation, Pagination } from 'swiper';
import Swiper from 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.mjs';

export default class CreateLeftRail {
  
    constructor (data ) {

        this.container = data.container;
       // data.container.classList.add("no-print");
        let Stage = this;
        let container = document.querySelector(data["container"]);
        container.classList.add("no-print");
        Stage.container = container;

        /* if left rail Logo exists :::::: */
        /* ::::::::::::::::::::::::::::::: */

        if (data["logo"]) {

            this.LoadLogo(data["logo"]);
        }

        /* if left rail Search exists :::: */
        /* ::::::::::::::::::::::::::::::: */

        this.LoadOpenCloseBtn();
       
        return Stage;
    
    }

    LoadLogo (data) {

        let leftRailLogoContainer = document.createElement("div");
        leftRailLogoContainer.setAttribute("class","logo");
        this.container.appendChild(leftRailLogoContainer);

        let leftRailLogo = document.createElement("img");
        leftRailLogo.setAttribute("src",data["src"]);
        leftRailLogo.setAttribute("alt", data["alt"]);
        leftRailLogoContainer.appendChild(leftRailLogo);

        leftRailLogoContainer.addEventListener("click", (event)=> {
            data.click(event);
        })

    }

    LoadSearch (data) {

       // console.log(data);

        let SearchBox = document.createElement("input");
        SearchBox.setAttribute("type","text");
        SearchBox.setAttribute("value",data["label"]);
        this.container.appendChild(SearchBox);

        let SearchBoxSections = document.createElement("div");
        this.container.appendChild(SearchBoxSections);

        let SearchBoxSectionsGroup = document.createElement("ul");
        SearchBoxSectionsGroup.setAttribute("class","search-field-menu");
        SearchBoxSections.appendChild(SearchBoxSectionsGroup);

        data["sections"].forEach((element) => {
            let eachSection = document.createElement("li");
            eachSection.innerHTML = element;
            SearchBoxSectionsGroup.appendChild(eachSection);
            eachSection.addEventListener("click", (e) => {
                console.log(e.currentTarget);
                e.currentTarget.classList.add("selected");
            });
        });
    }

    LoadBookInformation (data) {

        let bookTitle = document.createElement("div");
        bookTitle.setAttribute("class","featured-title");
        bookTitle.innerHTML = data["feature-section-lable"];
        this.container.appendChild(bookTitle);

        let slideshow = document.createElement("div");
        slideshow.setAttribute("class","swiper");
        this.container.appendChild(slideshow);

        let slideshowWraper = document.createElement("div");
        slideshowWraper.setAttribute("class","swiper-wrapper");
        slideshow.appendChild(slideshowWraper);

        data["book-information"].forEach((element) => {

            let eachSlide = document.createElement("a");
            eachSlide.setAttribute("class","swiper-slide");
            eachSlide.setAttribute("href",element["link"]);
            eachSlide.setAttribute("data-id",element["id"]);
            slideshowWraper.appendChild(eachSlide);

            let eachSlideImg = document.createElement("img");
            eachSlideImg.setAttribute("class","slide-img");
            eachSlideImg.setAttribute("alt","book-cover");
            eachSlideImg.setAttribute("src",element["cover-picture"]);
            eachSlide.appendChild(eachSlideImg);

            let eachSlideCopy = document.createElement("p");
            eachSlideCopy.setAttribute("class","slide-img");
            eachSlideCopy.innerHTML = element["title"];
            eachSlide.appendChild(eachSlideCopy);

        });
          
        if (data["book-information"].length > 1) {

            let swiperPagination = document.createElement("div");
            swiperPagination.setAttribute("class","swiper-pagination");
            slideshow.appendChild(swiperPagination);
    
            let swiperNavPrev = document.createElement("div");
            swiperNavPrev.setAttribute("class","swiper-button-prev");
            slideshow.appendChild(swiperNavPrev);
    
            let swiperNavNext = document.createElement("div");
            swiperNavNext.setAttribute("class","swiper-button-next");
            slideshow.appendChild(swiperNavNext);  
            
            new Swiper('.left-rail .swiper', {
               // "modules": [Navigation, Pagination],
                "spaceBetween": 10,
                "loop": true,
                "pagination": {
                "el": '.left-rail .swiper-pagination',
                "type": 'bullets',
                },
                "navigation": {
                    "nextEl": '.left-rail .swiper-button-next',
                    "prevEl": '.left-rail .swiper-button-prev',
                },
            
            });
        }

    }

    LoadTagCloud (data) {

        let TargetPageURL = "";

        let wordCloudContainer = document.createElement("div");
        wordCloudContainer.setAttribute("class","word-cloud");
        this.container.appendChild(wordCloudContainer);

        let wordCloudgroup = document.createElement("ul");
        wordCloudgroup.setAttribute("role","navigation");
        wordCloudgroup.setAttribute("class","cloud");
        wordCloudgroup.setAttribute("aria-label","tag cloud");
        wordCloudContainer.appendChild(wordCloudgroup);

        data.forEach((element) => {

            let eachCloudItem = document.createElement("li");
            wordCloudgroup.appendChild(eachCloudItem);

            let eachCloudAtag = document.createElement("div");
            eachCloudAtag.setAttribute("data-url",element["link"]);
            eachCloudAtag.setAttribute("data-id",element["id"]);
            eachCloudAtag.setAttribute("data-weight",element["weight"]);
            eachCloudAtag.innerHTML = element["label"];
            eachCloudItem.appendChild(eachCloudAtag);
            eachCloudItem.addEventListener("click", (event) => {
                //console.log(event.target.dataset["id"]);
                window.open(TargetPageURL + "?id=" + event.target.dataset["id"], "_self" );
            })

        });

    }

    LoadOpenCloseBtn () {
        let openCloseBtn = document.createElement("div");
        openCloseBtn.setAttribute("class","open-close-btn");
        this.container.appendChild(openCloseBtn);
       // console.log("create open close Btn ");
        openCloseBtn.addEventListener("click", (event)=> {
            //console.log(event.target.parentElement.parentElement);
            event.target.parentElement.parentElement.setAttribute("data-no-leftrail", true);
        })
    }

   

   


}